import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import ScrollArrow from 'components/shared/ScrollArrow';

const Wrapper = styled(Section)`
  position: relative;
  width: 100%;
`;

const ContentInner = styled.div`
  position: relative;
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 50px;
  ${({ theme }) => theme.mq.md} {
    max-width: 70vw;
    padding-right: 0;
    text-align: center;
  }
`;

const Text = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
  transition: 0.3s;
  line-height: 1.6;
  a {
    text-decoration: none;
    transition: 0.2s;
  }
  ${({ theme }) => theme.mq.xl} {
    font-weight: 400;
    color: #ffffffb3;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -35px;
  ${({ theme }) => theme.mq.xxl} {
    top: -33px;
  }
`;

const PostFooter = ({ text }) => {
  return (
    <Wrapper as="footer">
      <Content>
        <ContentInner>
          <TextWrapper>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </TextWrapper>
          <ArrowWrapper>
            <ScrollArrow />
          </ArrowWrapper>
        </ContentInner>
      </Content>
    </Wrapper>
  );
};

PostFooter.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PostFooter;
