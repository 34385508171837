import React from 'react';
import PropTypes from 'prop-types';
import useMedia from 'hooks/useMedia';
import AboutSectionDesktop from 'components/Contents/Post1/AboutSectionDesktop';
import AboutSectionMobile from 'components/Contents/Post1/AboutSectionMobile';

const AboutSection = ({
  inverted,
  last,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  sectionFive,
}) => {
  const matchesLGDesktop = useMedia('lg', true);
  const matchesLGMobile = useMedia('lg', false);
  return (
    <>
      {matchesLGDesktop && (
        <AboutSectionDesktop
          inverted={inverted}
          last={last}
          sectionOne={sectionOne}
          sectionTwo={sectionTwo}
          sectionThree={sectionThree}
          sectionFour={sectionFour}
          sectionFive={sectionFive}
        />
      )}
      {!matchesLGMobile && (
        <AboutSectionMobile
          inverted={inverted}
          last={last}
          sectionOne={sectionOne}
          sectionTwo={sectionTwo}
          sectionThree={sectionThree}
          sectionFour={sectionFour}
          sectionFive={sectionFive}
        />
      )}
    </>
  );
};

AboutSection.propTypes = {
  inverted: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  sectionOne: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionTwo: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionThree: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFour: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFive: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AboutSection;
