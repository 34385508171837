const splitToChunks = (arr, parts) => {
  const chunks = [];
  while (arr.length) {
    const chunkSize = Math.ceil(arr.length / parts--);
    const chunk = arr.slice(0, chunkSize);
    chunks.push(chunk);
    arr = arr.slice(chunkSize);
  }
  return chunks;
};

export default splitToChunks;
