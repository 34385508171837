import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';
import splitToChunks from 'utils/splitToChunks';
import { LangContext } from 'contexts/LangContext';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 10px 0 0;
  width: 100%;
  max-width: max-content;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1.6rem;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  border: 1px solid #ffffff;
  opacity: 0.7;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
  }
  div {
    display: flex;
    align-items: center;
    padding: 0 5px;
    transform: rotate(180deg);
  }
  span {
    margin-right: 10px;
    display: block;
  }
`;

const ExpandedColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:last-child) {
    margin: 0 0 80px;
  }
  & > p:not(:last-child) {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    flex-basis: 50%;
    &:not(:last-child) {
      margin: 0 40px 0 0;
    }
  }
  ${({ theme }) => theme.mq.xl} {
    flex-basis: 33%;
    &:not(:last-child) {
      margin: 0 80px 0 0;
    }
  }
`;

const Anchor = styled.span`
  display: block;
  height: 0;
  width: 0;
`;

const ExpandedSectionWrapper = styled.div`
  display: flex;
  padding: ${({ spaceTop }) => (spaceTop ? '80px 0 80px' : '20px 0 80px')};
`;

const Text = styled.p`
  font-weight: 300;
  line-height: 1.8;
  ${({ theme }) => theme.mq.md} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2rem;
  }
`;

const ExpandedSection = ({
  id,
  questions,
  chunkParts,
  visible,
  hideSection,
  spaceTop,
}) => {
  const { translate } = useContext(LangContext);

  return (
    <>
      <Anchor id={id} aria-hidden />
      {visible && (
        <ExpandedSectionWrapper spaceTop={spaceTop}>
          {splitToChunks(questions, chunkParts).map((column, index) => (
            <ExpandedColumn key={index}>
              {column.map((text, i) => (
                <React.Fragment key={i}>
                  <Text dangerouslySetInnerHTML={{ __html: text }} />
                  {chunkParts - 1 === index && i === column.length - 1 && (
                    <Button onClick={() => hideSection(`#${id}`)}>
                      <span>{translate('readLess')}</span>
                      <div>
                        <ArrowIcon />
                      </div>
                    </Button>
                  )}
                </React.Fragment>
              ))}
            </ExpandedColumn>
          ))}
        </ExpandedSectionWrapper>
      )}
    </>
  );
};

ExpandedSection.propTypes = {
  id: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  chunkParts: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  hideSection: PropTypes.func.isRequired,
  spaceTop: PropTypes.bool,
};

ExpandedSection.defaultProps = {
  spaceTop: false,
};

export default ExpandedSection;
