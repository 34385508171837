/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import LazyImage from 'components/shared/LazyImage';
import { LangContext } from 'contexts/LangContext';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';
import scrollToSection from 'utils/scrollToSection';
import useMedia from 'hooks/useMedia';
import ExpandedSection from './ExpandedSection';

const StyledSection = styled(Section)`
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 20px 0;
  margin: 0 auto;
  ${({ theme }) => theme.mq.s} {
    padding: 25px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  line-height: 1.8;
  ${({ theme }) => theme.mq.md} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 20px auto 0;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  margin: 20px 0 0;
  width: 100%;
  max-width: max-content;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1.7rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  border: 1px solid #ffffff;
  opacity: 0.7;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
  }
  div {
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  span {
    margin-right: 10px;
    display: block;
  }
`;

const AboutSectionMobile = ({
  inverted,
  last,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  sectionFive,
}) => {
  const [chunkParts, setChunkParts] = useState(3);
  const [expandedSections, setExpandedSections] = useState([]);
  const { translate } = useContext(LangContext);

  const matchesMD = useMedia('md');

  const handleButtonClick = sectionID => {
    if (expandedSections.includes(sectionID)) {
      scrollToSection(sectionID);
      return;
    }

    setExpandedSections([...expandedSections, sectionID]);
    setTimeout(() => scrollToSection(sectionID), 300);
  };

  useEffect(() => {
    if (matchesMD) setChunkParts(2);
    else setChunkParts(1);
  }, [matchesMD]);

  const hideSection = (origin, destination) => {
    scrollToSection(origin);
    setExpandedSections(expandedSections.filter(el => el !== destination));
  };

  const SectionOne = ({ section, sectionKey }) => (
    <StyledSection noTop>
      <Content as="article">
        <Wrapper>
          <ContentWrapper>
            <HeadingTwo
              dangerouslySetInnerHTML={{
                __html: section.creatorOne.name,
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: section.creatorOne.text,
              }}
            />
          </ContentWrapper>
          <ContentWrapper>
            <HeadingTwo
              dangerouslySetInnerHTML={{
                __html: section.creatorTwo.name,
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: section.creatorTwo.text,
              }}
            />
          </ContentWrapper>
          <Text dangerouslySetInnerHTML={{ __html: section.creatorText }} />
          <ImageWrapper>
            <LazyImage src={section.imageSmall} alt="background" />
          </ImageWrapper>
          <ContentWrapper id={`section-${sectionKey}-content`}>
            <Text dangerouslySetInnerHTML={{ __html: section.text }} />
            {section.questions && (
              <Link
                as="button"
                onClick={() =>
                  handleButtonClick(`#section-${sectionKey}-expanded`)
                }
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={section.imageBig} alt="background" />
          </ImageWrapper>
          <ContentWrapper>
            <Link href={section.buttonBigLink} target="_blank">
              <span
                dangerouslySetInnerHTML={{
                  __html: section.buttonBigText,
                }}
              />
              <div>
                <ArrowIcon />
              </div>
            </Link>
          </ContentWrapper>
        </Wrapper>
        <ExpandedSection
          id={`section-${sectionKey}-expanded`}
          chunkParts={chunkParts}
          questions={section.questions}
          visible={expandedSections.includes(`#section-${sectionKey}-expanded`)}
          hideSection={() =>
            hideSection(
              `#section-${sectionKey}-content`,
              `#section-${sectionKey}-expanded`
            )
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionTwo = () => (
    <StyledSection noTop noBottom>
      <Content as="article">
        <Wrapper>
          <ContentWrapper>
            <HeadingTwo dangerouslySetInnerHTML={{ __html: sectionTwo.name }} />
            <Text
              dangerouslySetInnerHTML={{ __html: sectionTwo.creatorText }}
            />
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionTwo.imageTwo} alt="background" />
          </ImageWrapper>
          <ContentWrapper id="section-two-content">
            <Text dangerouslySetInnerHTML={{ __html: sectionTwo.text }} />
            {sectionTwo.questions && (
              <Link
                as="button"
                onClick={() => handleButtonClick('#section-two-expanded')}
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionTwo.imageOne} alt="background" />
          </ImageWrapper>
          <ContentWrapper>
            <Link href={sectionTwo.buttonBigLink} target="_blank">
              <span
                dangerouslySetInnerHTML={{
                  __html: sectionTwo.buttonBigText,
                }}
              />
              <div>
                <ArrowIcon />
              </div>
            </Link>
          </ContentWrapper>
        </Wrapper>
        <ExpandedSection
          id="section-two-expanded"
          chunkParts={chunkParts}
          questions={sectionTwo.questions}
          visible={expandedSections.includes('#section-two-expanded')}
          hideSection={() =>
            hideSection('#section-two-content', '#section-two-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionThree = () => (
    <StyledSection noTop noBottom>
      <Content as="article">
        <Wrapper>
          <ContentWrapper>
            <HeadingTwo
              dangerouslySetInnerHTML={{ __html: sectionThree.name }}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: sectionThree.creatorText }}
            />
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionThree.imageTwo} alt="background" />
          </ImageWrapper>
          <ContentWrapper id="section-three-content">
            <Text dangerouslySetInnerHTML={{ __html: sectionThree.text }} />
            {sectionThree.questions && (
              <Link
                as="button"
                onClick={() => handleButtonClick('#section-three-expanded')}
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionThree.imageOne} alt="background" />
          </ImageWrapper>
          <ContentWrapper>
            <Link href={sectionThree.buttonBigLink} target="_blank">
              <span
                dangerouslySetInnerHTML={{
                  __html: sectionThree.buttonBigText,
                }}
              />
              <div>
                <ArrowIcon />
              </div>
            </Link>
          </ContentWrapper>
        </Wrapper>
        <ExpandedSection
          id="section-three-expanded"
          chunkParts={chunkParts}
          questions={sectionThree.questions}
          visible={expandedSections.includes('#section-three-expanded')}
          hideSection={() =>
            hideSection('#section-three-content', '#section-three-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionFour = () => (
    <StyledSection noTop>
      <Content as="article">
        <Wrapper>
          <ContentWrapper>
            <HeadingTwo
              dangerouslySetInnerHTML={{ __html: sectionFour.name }}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: sectionFour.creatorText }}
            />
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionFour.imageOne} alt="background" />
          </ImageWrapper>
          <ContentWrapper id="section-four-content">
            <Text dangerouslySetInnerHTML={{ __html: sectionFour.text }} />
            {sectionFour.questions && (
              <Link
                as="button"
                onClick={() => handleButtonClick('#section-four-expanded')}
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </ContentWrapper>
          <ImageWrapper>
            <LazyImage src={sectionFour.imageTwo} alt="background" />
          </ImageWrapper>
          <ContentWrapper>
            <Link href={sectionFour.buttonBigLink} target="_blank">
              <span
                dangerouslySetInnerHTML={{
                  __html: sectionFour.buttonBigText,
                }}
              />
              <div>
                <ArrowIcon />
              </div>
            </Link>
          </ContentWrapper>
        </Wrapper>
        <ExpandedSection
          id="section-four-expanded"
          chunkParts={chunkParts}
          questions={sectionFour.questions}
          visible={expandedSections.includes('#section-four-expanded')}
          hideSection={() =>
            hideSection('#section-four-content', '#section-four-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  return (
    <>
      {inverted && !last && (
        <>
          {sectionThree.name && <SectionThree />}
          {sectionTwo.name && <SectionTwo />}
          {sectionFour.name && <SectionFour />}
          <SectionOne sectionKey="one" section={sectionOne} />
        </>
      )}
      {!inverted && !last && (
        <>
          <SectionOne sectionKey="one" section={sectionOne} />
          <SectionOne sectionKey="five" section={sectionFive} />
          {sectionTwo.name && <SectionTwo />}
          {sectionThree.name && <SectionThree />}
          {sectionFour.name && <SectionFour />}
        </>
      )}
      {last && (
        <>
          <SectionOne sectionKey="one" section={sectionOne} />
          <SectionOne sectionKey="five" section={sectionFive} />
          {sectionTwo.name && <SectionTwo />}
          {sectionThree.name && <SectionThree />}
          {sectionFour.name && <SectionFour />}
        </>
      )}
    </>
  );
};

AboutSectionMobile.propTypes = {
  inverted: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  sectionOne: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionTwo: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionThree: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFour: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFive: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AboutSectionMobile;
