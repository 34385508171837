/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import LazyImage from 'components/shared/LazyImage';
import ExpandedSection from 'components/Contents/Post1/ExpandedSection';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';
import scrollToSection from 'utils/scrollToSection';
import useMedia from 'hooks/useMedia';
import { LangContext } from 'contexts/LangContext';

const StyledSection = styled(Section)`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    z-index: 1;
  }
`;

const SectionOneContent = styled.div`
  max-width: 540px;
  padding: 0 80px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 50%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 80px;
  max-width: 560px;
  margin: 0 auto;
`;

const Text = styled.p`
  font-weight: 300;
  line-height: 1.8;
  ${({ theme }) => theme.mq.md} {
    font-size: 1.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2rem;
  }
`;

const StyledContentWrapper = styled(ContentWrapper)`
  max-width: 700px;
  & > p {
    max-width: 450px;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 20px 0 0;
  width: 100%;
  max-width: max-content;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1.3rem;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  border: 1px solid #ffffff;
  opacity: 0.7;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
  }
  div {
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  span {
    margin-right: 10px;
    display: block;
  }
`;

const Quote = styled(HeadingTwo)`
  max-width: 840px;
  padding-right: 15px;
  transition: 0.15s;
  ${({ theme }) => theme.mq.xl} {
    font-size: 3.8rem !important;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5rem !important;
  }
`;

const SectionBottomContent = styled.div`
  position: relative;
  margin-top: -100px;
  z-index: -1;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 150px;
`;

const AbsoluteImage = styled.div`
  position: absolute;
  right: 0;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '0')};
  z-index: 1;
  width: 300px;
  transition: 0.15s;
  ${({ theme }) => theme.mq.xl} {
    width: 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 490px;
  }
`;

const SectionOneBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 300px;
  padding-top: 50px;
  ${({ theme }) => theme.mq.xl} {
    padding-right: 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-top: 70px;
    padding-right: 530px;
  }
  & > div {
    padding-right: 30px;
    flex-basis: ${({ full }) => (full ? '33%' : '50%')};
    &:nth-child(1) {
      max-width: 400px;
    }
    &:nth-child(2) {
      max-width: 400px;
    }
  }
`;

const SectionOneBottomInfo = styled.div`
  margin-top: 130px;
  max-width: 250px;
  ${({ theme }) => theme.mq.xl} {
    margin-top: 155px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 205px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
  ${({ theme }) => theme.mq.xl} {
    padding: 75px 0;
  }
`;

const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  max-width: max-content;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  border: 1px solid #ffffff;
  transition: 0.3s;
  ${({ theme }) => theme.mq.md} {
    font-size: 2rem;
    opacity: 0.7;
    font-weight: 300;
    &:hover {
      opacity: 1;
    }
    br {
      display: none;
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.4rem;
  }
  div {
    display: flex;
    align-items: center;
  }
  span {
    margin-right: 20px;
  }
`;

const SectionThreeContent = styled.div`
  max-width: 340px;
  padding: 0 40px 0 0;
  & + h2 {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 540px;
  }
`;

const StyledImageWrapper = styled(ImageWrapper)`
  padding-right: 0;
  padding-left: 150px;
  ${AbsoluteImage} {
    right: auto;
    left: 0;
    width: 400px;
    transform: translateY(70%);
    ${({ theme }) => theme.mq.xl} {
      width: 500px;
    }
    ${({ theme }) => theme.mq.xxl} {
      width: 600px;
    }
  }
`;

const BottomContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding-left: 430px;
  padding-top: 50px;
  ${({ theme }) => theme.mq.xl} {
    padding-top: 70px;
    padding-left: 530px;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-left: 630px;
  }
`;

const BottomContentInner = styled.div`
  max-width: 580px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.xxl} {
    max-width: 770px;
  }
`;

const StyledBottomContent = styled(ContentWrapper)`
  margin: 0 auto;
  max-width: 800px;
  p {
    max-width: 600px;
  }
  h2 {
    margin-top: 80px;
  }
`;

const SectionFourButtonWrapper = styled(ButtonWrapper)`
  padding: 0 80px 0 80px !important;
  max-width: 800px;
  margin: auto auto 0;
  justify-content: flex-start;
`;

const StyledText = styled(Text)`
  min-height: 120px;
`;

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteText = styled(Text)`
  margin-top: -20px;
`;

const ParallaxWrapper = styled.div`
  /* transition: 0.15s; */
  /* transform: translate3d(0px, -25px, 0px); */
`;

const AboutSectionDesktop = ({
  inverted,
  last,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  sectionFive,
}) => {
  const parentRef = useRef(null);
  const textRef = useRef(null);

  const [chunkParts, setChunkParts] = useState(3);
  const [expandedSections, setExpandedSections] = useState([]);
  const { translate } = useContext(LangContext);

  const matchesXL = useMedia('xl');

  const handleButtonClick = sectionID => {
    if (expandedSections.includes(sectionID)) {
      scrollToSection(sectionID);
      return;
    }

    setExpandedSections([...expandedSections, sectionID]);
    setTimeout(() => scrollToSection(sectionID), 300);
  };

  const getAbsoluteBottomPx = () => {
    if (!parentRef.current || !textRef.current) return 0;
    const offsetHeight = textRef.current.offsetHeight;
    const contentHeight = textRef.current.children[0].offsetHeight;
    const bottom = offsetHeight - contentHeight;
    return bottom || 0;
  };

  useEffect(() => {
    if (matchesXL) setChunkParts(3);
    else setChunkParts(2);
  }, [matchesXL]);

  // useEffect(() => {
  //   const handleParallax = () => {
  //     const parallaxElements = [...document.querySelectorAll('.parallax')];
  //     parallaxElements.forEach(el => {
  //       const limit = el.tagName === 'DIV' ? 25 : 40;

  //       const { top, height } = el.getBoundingClientRect();
  //       const offset =
  //         (top + height / 2 - (window.innerHeight + 200) / 2) * 0.15;
  //       if (offset < limit && offset > -limit) {
  //         let translateY = '0%';
  //         const fixedPerc = [...el.classList].find(className =>
  //           className.includes('fixed')
  //         );
  //         const topOnly = [...el.classList].find(className =>
  //           className.includes('top-only')
  //         );
  //         if (topOnly && offset < 0) return;

  //         if (fixedPerc) {
  //           translateY = fixedPerc.split('-')[1];
  //         }
  //         el.style.transform = `translate3d(0px, calc(${translateY} + ${
  //           offset * -1
  //         }px), 0px)`;
  //       }
  //     });
  //   };

  //   window.addEventListener('scroll', handleParallax);
  //   return () => window.removeEventListener('scroll', handleParallax);
  // }, []);

  const hideSection = (origin, destination) => {
    scrollToSection(origin);
    setExpandedSections(expandedSections.filter(el => el !== destination));
  };

  const SectionOne = ({ section, sectionKey }) => (
    <StyledSection noBottom>
      <Content as="article">
        <Wrapper>
          <QuoteWrapper>
            <Quote dangerouslySetInnerHTML={{ __html: section.quote }} />
            {section.quoteText && (
              <QuoteText
                dangerouslySetInnerHTML={{
                  __html: section.quoteText,
                }}
              />
            )}
          </QuoteWrapper>
          <SectionOneContent id={`section-${sectionKey}-content`}>
            <Text dangerouslySetInnerHTML={{ __html: section.text }} />
            {section.questions && (
              <Link
                as="button"
                onClick={() =>
                  handleButtonClick(`#section-${sectionKey}-expanded`)
                }
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </SectionOneContent>
        </Wrapper>
        <SectionBottomContent>
          <ImageWrapper>
            <ParallaxWrapper className="parallax">
              <LazyImage src={section.imageBig} alt="background image" />
            </ParallaxWrapper>
          </ImageWrapper>
          <AbsoluteImage
            bottom={getAbsoluteBottomPx()}
            className="parallax top-only"
          >
            <LazyImage src={section.imageSmall} alt="background image" />
          </AbsoluteImage>
          <SectionOneBottom ref={parentRef} full={section.creatorText}>
            <div>
              <HeadingTwo
                dangerouslySetInnerHTML={{
                  __html: section.creatorOne.name,
                }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: section.creatorOne.text,
                }}
              />
            </div>
            <div>
              <HeadingTwo
                dangerouslySetInnerHTML={{
                  __html: section.creatorTwo.name,
                }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: section.creatorTwo.text,
                }}
              />
            </div>
            <SectionOneBottomInfo ref={textRef}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: section.creatorText,
                }}
              />
            </SectionOneBottomInfo>
          </SectionOneBottom>
        </SectionBottomContent>
        <ButtonWrapper>
          <ButtonLink href={section.buttonBigLink} target="_blank">
            <span dangerouslySetInnerHTML={{ __html: section.buttonBigText }} />
            <div>
              <ArrowIcon />
            </div>
          </ButtonLink>
        </ButtonWrapper>
        <ExpandedSection
          id={`section-${sectionKey}-expanded`}
          chunkParts={chunkParts}
          questions={section.questions}
          visible={expandedSections.includes(`#section-${sectionKey}-expanded`)}
          hideSection={() =>
            hideSection(
              `#section-${sectionKey}-content`,
              `#section-${sectionKey}-expanded`
            )
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionTwo = () => (
    <StyledSection noTop noBottom>
      <Content as="article">
        <Wrapper>
          <Column>
            <ParallaxWrapper className="parallax">
              <LazyImage src={sectionTwo.imageOne} alt="content image" />
            </ParallaxWrapper>
            <ContentWrapper id="section-two-content">
              <Text dangerouslySetInnerHTML={{ __html: sectionTwo.text }} />
              {sectionTwo.questions && (
                <Link
                  as="button"
                  onClick={() => handleButtonClick('#section-two-expanded')}
                >
                  <span>{translate('readMore')}</span>
                  <div>
                    <ArrowIcon />
                  </div>
                </Link>
              )}
            </ContentWrapper>
            <QuoteWrapper>
              <Quote dangerouslySetInnerHTML={{ __html: sectionTwo.quote }} />
              {sectionTwo.quoteText && (
                <QuoteText
                  dangerouslySetInnerHTML={{
                    __html: sectionTwo.quoteText,
                  }}
                />
              )}
            </QuoteWrapper>
          </Column>
          <Column>
            <StyledContentWrapper>
              <HeadingTwo
                dangerouslySetInnerHTML={{ __html: sectionTwo.name }}
              />
              <Text
                dangerouslySetInnerHTML={{ __html: sectionTwo.creatorText }}
              />
            </StyledContentWrapper>
            <ParallaxWrapper className="parallax">
              <LazyImage src={sectionTwo.imageTwo} alt="creator image" />
            </ParallaxWrapper>
          </Column>
        </Wrapper>
        <ButtonWrapper>
          <ButtonLink href={sectionTwo.buttonBigLink} target="_blank">
            <span
              dangerouslySetInnerHTML={{ __html: sectionTwo.buttonBigText }}
            />
            <div>
              <ArrowIcon />
            </div>
          </ButtonLink>
        </ButtonWrapper>
        <ExpandedSection
          id="section-two-expanded"
          chunkParts={chunkParts}
          questions={sectionTwo.questions}
          visible={expandedSections.includes('#section-two-expanded')}
          hideSection={() =>
            hideSection('#section-two-content', '#section-two-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionThree = () => (
    <StyledSection noTop noBottom>
      <Content as="article">
        <Wrapper>
          <SectionThreeContent id="section-three-content">
            <Text dangerouslySetInnerHTML={{ __html: sectionThree.text }} />
            {sectionThree.questions && (
              <Link
                as="button"
                onClick={() => handleButtonClick('#section-three-expanded')}
              >
                <span>{translate('readMore')}</span>
                <div>
                  <ArrowIcon />
                </div>
              </Link>
            )}
          </SectionThreeContent>
          <QuoteWrapper>
            <Quote dangerouslySetInnerHTML={{ __html: sectionThree.quote }} />
            {sectionThree.quoteText && (
              <QuoteText
                dangerouslySetInnerHTML={{
                  __html: sectionThree.quoteText,
                }}
              />
            )}
          </QuoteWrapper>
        </Wrapper>
        <SectionBottomContent>
          <StyledImageWrapper>
            <ParallaxWrapper className="parallax">
              <LazyImage src={sectionThree.imageOne} alt="background image" />
            </ParallaxWrapper>
            <AbsoluteImage className="parallax fixed-70%%">
              <LazyImage src={sectionThree.imageTwo} alt="background image" />
            </AbsoluteImage>
          </StyledImageWrapper>
          <BottomContent>
            <BottomContentInner>
              <HeadingTwo
                dangerouslySetInnerHTML={{ __html: sectionThree.name }}
              />
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: sectionThree.creatorText,
                }}
              />
            </BottomContentInner>
          </BottomContent>
        </SectionBottomContent>
        <ButtonWrapper>
          <ButtonLink href={sectionThree.buttonBigLink} target="_blank">
            <span
              dangerouslySetInnerHTML={{
                __html: sectionThree.buttonBigText,
              }}
            />
            <div>
              <ArrowIcon />
            </div>
          </ButtonLink>
        </ButtonWrapper>
        <ExpandedSection
          id="section-three-expanded"
          chunkParts={chunkParts}
          questions={sectionThree.questions}
          visible={expandedSections.includes('#section-three-expanded')}
          hideSection={() =>
            hideSection('#section-three-content', '#section-three-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  const SectionFour = () => (
    <StyledSection noTop big>
      <Content as="article">
        <Wrapper>
          <Column>
            <StyledContentWrapper>
              <HeadingTwo
                dangerouslySetInnerHTML={{ __html: sectionFour.name }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: sectionFour.creatorText,
                }}
              />
            </StyledContentWrapper>
            <LazyImage src={sectionFour.imageTwo} alt="creator image" />
          </Column>
          <Column>
            <ParallaxWrapper className="parallax">
              <LazyImage src={sectionFour.imageOne} alt="content image" />
            </ParallaxWrapper>
            <StyledBottomContent id="section-four-content">
              <Text dangerouslySetInnerHTML={{ __html: sectionFour.text }} />
              {sectionFour.questions && (
                <Link
                  as="button"
                  onClick={() => handleButtonClick('#section-four-expanded')}
                >
                  <span>{translate('readMore')}</span>
                  <div>
                    <ArrowIcon />
                  </div>
                </Link>
              )}
              <QuoteWrapper>
                <Quote
                  dangerouslySetInnerHTML={{ __html: sectionFour.quote }}
                />
                {sectionFour.quoteText && (
                  <QuoteText
                    dangerouslySetInnerHTML={{
                      __html: sectionFour.quoteText,
                    }}
                  />
                )}
              </QuoteWrapper>
            </StyledBottomContent>
            <SectionFourButtonWrapper>
              <ButtonLink href={sectionFour.buttonBigLink} target="_blank">
                <span
                  dangerouslySetInnerHTML={{
                    __html: sectionFour.buttonBigText,
                  }}
                />
                <div>
                  <ArrowIcon />
                </div>
              </ButtonLink>
            </SectionFourButtonWrapper>
          </Column>
        </Wrapper>
        <ExpandedSection
          id="section-four-expanded"
          chunkParts={chunkParts}
          questions={sectionFour.questions}
          visible={expandedSections.includes('#section-four-expanded')}
          spaceTop
          hideSection={() =>
            hideSection('#section-four-content', '#section-four-expanded')
          }
        />
      </Content>
    </StyledSection>
  );

  return (
    <>
      {inverted && !last && (
        <>
          {sectionThree.name && <SectionThree />}
          {sectionTwo.name && <SectionTwo />}
          {sectionFour.name && <SectionFour />}
          <SectionOne sectionKey="one" section={sectionOne} />
        </>
      )}
      {!inverted && !last && (
        <>
          <SectionOne sectionKey="one" section={sectionOne} />
          <SectionOne sectionKey="five" section={sectionFive} />
          {sectionTwo.name && <SectionTwo />}
          {sectionThree.name && <SectionThree />}
          {sectionFour.name && <SectionFour />}
        </>
      )}
      {last && (
        <>
          <SectionOne sectionKey="one" section={sectionOne} />
          <SectionOne sectionKey="five" section={sectionFive} />
          {sectionThree.name && <SectionThree />}
          {sectionFour.name && <SectionFour />}
          {sectionTwo.name && <SectionTwo />}
        </>
      )}
    </>
  );
};

AboutSectionDesktop.propTypes = {
  inverted: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  sectionOne: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionTwo: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionThree: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFour: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionFive: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AboutSectionDesktop;
