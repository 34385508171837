import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from 'components/shared/SEO';
import PostHeader from 'components/Headers/PostHeader';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import Player from 'components/Player/Player';
import AboutSection from 'components/Contents/Post1/AboutSection';
import { Link as Button } from 'components/Contents/Post1/AboutSectionDesktop';
import { LangContext } from 'contexts/LangContext';
import VideosSection from 'components/Contents/VideosSection';
import InvestmentsSection from 'components/Contents/InvestmentsSection';
import scrollToSection from 'utils/scrollToSection';
import PostFooter from 'components/Footer/PostFooter';

const ButtonWrapper = styled.div`
  & > button {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const PostLayout = ({ data }) => {
  const { lang, translate } = useContext(LangContext);

  const POST = data.wpgraphql.post.Post;
  const POST_SEO = data.wpgraphql.post.seo;
  const SLUG = data.wpgraphql.post.slug;
  const PAGE =
    lang === 'pl'
      ? data.wpgraphql?.homePL.PageHome
      : data.wpgraphql?.homeEN.PageHome;

  const SECTION_ONE = POST.aboutSectionOne;
  const SECTION_TWO = POST.aboutSectionTwo;
  const SECTION_THREE = POST.aboutSectionThree;
  const SECTION_FOUR = POST.aboutSectionFour;
  const SECTION_FIVE = POST.aboutSectionFive;

  const ALL_VIDEOS = data.wpgraphql.posts.nodes
    .map(
      ({
        slug,
        Post: {
          videoTitle,
          videoDescription,
          videoNumber,
          videoPremierDate,
          videoPromoted,
          videoDuration,
          videoUrl,
          videoThumbnail,
          videoTrailer,
        },
      }) => ({
        number: videoNumber,
        title: videoTitle,
        url: videoUrl,
        excerpt: videoDescription,
        thumbnail: videoThumbnail.imageFile.childImageSharp.fluid,
        duration: videoDuration,
        premierDate: new Date(
          `${videoPremierDate.split(' ')[0].split('/').reverse().join('/')} ${
            videoPremierDate.split(' ')[1]
          } ${videoPremierDate.split(' ')[2]}`
        ),
        promoted: videoPromoted,
        trailer: videoTrailer,
        slug,
      })
    )
    .filter(({ slug }) =>
      lang === 'pl' ? !slug.includes('_') : slug.includes(`${lang}_`)
    )
    .sort((a, b) => a.number - b.number);

  const INVESTMENTS = PAGE.allInvestmentsList.map(
    ({
      investmentTitle,
      investmentVideoUrl,
      investmentThumbnail,
      investmentVideoDuration,
    }) => ({
      title: investmentTitle,
      url: investmentVideoUrl,

      thumbnail: investmentThumbnail.imageFile.childImageSharp.fluid,
      duration: investmentVideoDuration,
    })
  );

  const questionsOne = useMemo(
    () =>
      SECTION_ONE.aboutSectionQuestions &&
      SECTION_ONE.aboutSectionQuestions.map(
        ({ aboutSectionQuestionsQuestion }) => aboutSectionQuestionsQuestion
      ),
    [SECTION_ONE.aboutSectionQuestions]
  );

  const questionsTwo = useMemo(
    () =>
      SECTION_TWO.aboutSectionQuestions &&
      SECTION_TWO.aboutSectionQuestions.map(
        ({ aboutSectionQuestionsQuestion }) => aboutSectionQuestionsQuestion
      ),
    [SECTION_TWO.aboutSectionQuestions]
  );

  const questionsThree = useMemo(
    () =>
      SECTION_THREE.aboutSectionQuestions &&
      SECTION_THREE.aboutSectionQuestions.map(
        ({ aboutSectionQuestionsQuestion }) => aboutSectionQuestionsQuestion
      ),
    [SECTION_THREE.aboutSectionQuestions]
  );

  const questionsFour = useMemo(
    () =>
      SECTION_FOUR.aboutSectionQuestions &&
      SECTION_FOUR.aboutSectionQuestions.map(
        ({ aboutSectionQuestionsQuestion }) => aboutSectionQuestionsQuestion
      ),
    [SECTION_FOUR.aboutSectionQuestions]
  );

  const questionsFive = useMemo(
    () =>
      SECTION_FIVE.aboutSectionQuestions &&
      SECTION_FIVE.aboutSectionQuestions.map(
        ({ aboutSectionQuestionsQuestion }) => aboutSectionQuestionsQuestion
      ),
    [SECTION_FIVE.aboutSectionQuestions]
  );

  const isAfterPremiere =
    new Date(
      `${POST.videoPremierDate.split(' ')[0].split('/').reverse().join('/')} ${
        POST.videoPremierDate.split(' ')[1]
      } ${POST.videoPremierDate.split(' ')[2]}`
    ) < new Date();

  return (
    <>
      <SEO
        title={POST_SEO.title}
        description={POST_SEO.metaDesc}
        ogTitle={POST_SEO.opengraphTitle}
        ogDesc={POST_SEO.opengraphDescription}
        twitterTitle={POST_SEO.twitterTitle}
        twitterDesc={POST_SEO.twitterDescription}
        image={POST_SEO.opengraphImage && POST_SEO.opengraphImage.sourceUrl}
        robots={isAfterPremiere ? 'index, follow' : 'noindex, nofollow'}
      />
      <PostHeader
        images={POST.videoBannerImages.map(
          ({ videoBannerImagesImgOne, videoBannerImagesImg }) => ({
            src1: videoBannerImagesImgOne.imageFile.childImageSharp.fluid,
            src2: videoBannerImagesImg.imageFile.childImageSharp.fluid,
          })
        )}
        title={POST.videoBannerTitle}
        text={POST.videoBannerText}
      />
      <main>
        <Section>
          <Content id="promoted-video">
            <HeadingTwo
              dangerouslySetInnerHTML={{ __html: translate('watchVideo') }}
            />
            <Player
              video={{
                url: POST.videoUrl,
                thumbnail: POST.videoThumbnail.imageFile.childImageSharp.fluid,
              }}
            />
            <ButtonWrapper>
              <Button as="button" onClick={() => scrollToSection('#videos')}>
                {translate('allEpisodes')}
              </Button>
            </ButtonWrapper>
          </Content>
        </Section>
        <AboutSection
          inverted={
            SLUG.includes('architekci-kubatury') || SLUG.includes(`architects`)
          }
          last={
            SLUG.includes('architekci-krajobrazu') ||
            SLUG.includes('landscape-designers')
          }
          sectionOne={{
            quote: SECTION_ONE.aboutSectionQuote,
            quoteText: SECTION_ONE.aboutSectionQuoteText,
            text: SECTION_ONE.aboutSectionText,
            buttonBigText: SECTION_ONE.aboutSectionButtonBigText,
            buttonBigLink: SECTION_ONE.aboutSectionButtonBigLink,
            creatorOne: {
              name:
                SECTION_ONE.aboutSectionCreatorOne &&
                SECTION_ONE.aboutSectionCreatorOne.aboutSectionCreatorOneName,
              text:
                SECTION_ONE.aboutSectionCreatorOne &&
                SECTION_ONE.aboutSectionCreatorOne.aboutSectionCreatorOneText,
            },
            creatorTwo: {
              name:
                SECTION_ONE.aboutSectionCreatorTwo &&
                SECTION_ONE.aboutSectionCreatorTwo.aboutSectionCreatorTwoName,
              text:
                SECTION_ONE.aboutSectionCreatorTwo &&
                SECTION_ONE.aboutSectionCreatorTwo.aboutSectionCreatorTwoText,
            },
            creatorText: SECTION_ONE.aboutSectionCreatorText,
            imageBig:
              SECTION_ONE.aboutSectionImageBig &&
              SECTION_ONE.aboutSectionImageBig.imageFile.childImageSharp.fluid,
            imageSmall:
              SECTION_ONE.aboutSectionImageSmall &&
              SECTION_ONE.aboutSectionImageSmall.imageFile.childImageSharp
                .fluid,
            questions: questionsOne,
          }}
          sectionTwo={{
            name: SECTION_TWO.aboutSectionName,
            creatorText: SECTION_TWO.aboutSectionCreatorText,
            quote: SECTION_TWO.aboutSectionQuote,
            quoteText: SECTION_TWO.aboutSectionQuoteText,
            text: SECTION_TWO.aboutSectionText,
            buttonBigText: SECTION_TWO.aboutSectionButtonBigText,
            buttonBigLink: SECTION_TWO.aboutSectionButtonBigLink,
            imageOne:
              SECTION_TWO.aboutSectionImageOne &&
              SECTION_TWO.aboutSectionImageOne.imageFile.childImageSharp.fluid,
            imageTwo:
              SECTION_TWO.aboutSectionImageTwo &&
              SECTION_TWO.aboutSectionImageTwo.imageFile.childImageSharp.fluid,
            questions: questionsTwo,
          }}
          sectionThree={{
            name: SECTION_THREE.aboutSectionName,
            creatorText: SECTION_THREE.aboutSectionCreatorText,
            quote: SECTION_THREE.aboutSectionQuote,
            quoteText: SECTION_THREE.aboutSectionQuoteText,
            text: SECTION_THREE.aboutSectionText,
            buttonBigText: SECTION_THREE.aboutSectionButtonBigText,
            buttonBigLink: SECTION_THREE.aboutSectionButtonBigLink,
            imageOne:
              SECTION_THREE.aboutSectionImageOne &&
              SECTION_THREE.aboutSectionImageOne.imageFile.childImageSharp
                .fluid,
            imageTwo:
              SECTION_THREE.aboutSectionImageTwo &&
              SECTION_THREE.aboutSectionImageTwo.imageFile.childImageSharp
                .fluid,
            questions: questionsThree,
          }}
          sectionFour={{
            name: SECTION_FOUR.aboutSectionName,
            creatorText: SECTION_FOUR.aboutSectionCreatorText,
            quote: SECTION_FOUR.aboutSectionQuote,
            quoteText: SECTION_FOUR.aboutSectionQuoteText,
            text: SECTION_FOUR.aboutSectionText,
            buttonBigText: SECTION_FOUR.aboutSectionButtonBigText,
            buttonBigLink: SECTION_FOUR.aboutSectionButtonBigLink,
            imageOne:
              SECTION_FOUR.aboutSectionImageOne &&
              SECTION_FOUR.aboutSectionImageOne.imageFile.childImageSharp.fluid,
            imageTwo:
              SECTION_FOUR.aboutSectionImageTwo &&
              SECTION_FOUR.aboutSectionImageTwo.imageFile.childImageSharp.fluid,
            questions: questionsFour,
          }}
          sectionFive={{
            quote: SECTION_FIVE.aboutSectionQuote,
            quoteText: SECTION_FIVE.aboutSectionQuoteText,
            text: SECTION_FIVE.aboutSectionText,
            buttonBigText: SECTION_FIVE.aboutSectionButtonBigText,
            buttonBigLink: SECTION_FIVE.aboutSectionButtonBigLink,
            creatorOne: {
              name:
                SECTION_FIVE.aboutSectionCreatorOne &&
                SECTION_FIVE.aboutSectionCreatorOne.aboutSectionCreatorOneName,
              text:
                SECTION_FIVE.aboutSectionCreatorOne &&
                SECTION_FIVE.aboutSectionCreatorOne.aboutSectionCreatorOneText,
            },
            creatorTwo: {
              name:
                SECTION_FIVE.aboutSectionCreatorTwo &&
                SECTION_FIVE.aboutSectionCreatorTwo.aboutSectionCreatorTwoName,
              text:
                SECTION_FIVE.aboutSectionCreatorTwo &&
                SECTION_FIVE.aboutSectionCreatorTwo.aboutSectionCreatorTwoText,
            },
            creatorText: SECTION_FIVE.aboutSectionCreatorText,
            imageBig:
              SECTION_FIVE.aboutSectionImageBig &&
              SECTION_FIVE.aboutSectionImageBig.imageFile.childImageSharp.fluid,
            imageSmall:
              SECTION_FIVE.aboutSectionImageSmall &&
              SECTION_FIVE.aboutSectionImageSmall.imageFile.childImageSharp
                .fluid,
            questions: questionsFive,
          }}
        />
        <VideosSection title={translate('allEpisodes')} videos={ALL_VIDEOS} />
        {INVESTMENTS.length !== 0 && (
          <InvestmentsSection
            homepage
            title={PAGE.allInvestmentsHeading}
            investments={INVESTMENTS}
          />
        )}
      </main>
      <PostFooter text={POST.footerText} />
    </>
  );
};

PostLayout.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPostLayout($slug: ID!) {
    wpgraphql {
      post(id: $slug, idType: SLUG) {
        slug
        seo {
          title
          twitterTitle
          opengraphTitle
          metaDesc
          opengraphDescription
          twitterDescription
          opengraphImage {
            sourceUrl
          }
        }
        Post {
          videoTitle
          videoDescription
          videoNumber
          videoPremierDate
          videoPromoted
          videoDuration
          videoUrl
          videoBannerTitle
          videoBannerText
          videoBannerImages {
            videoBannerImagesImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            videoBannerImagesImgOne {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          videoThumbnail {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutSectionOne {
            aboutSectionQuote
            aboutSectionQuoteText
            aboutSectionText
            aboutSectionButtonBigText
            aboutSectionButtonBigLink
            aboutSectionCreatorOne {
              aboutSectionCreatorOneName
              aboutSectionCreatorOneText
            }
            aboutSectionCreatorTwo {
              aboutSectionCreatorTwoName
              aboutSectionCreatorTwoText
            }
            aboutSectionCreatorText
            aboutSectionImageBig {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionImageSmall {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionQuestions {
              aboutSectionQuestionsQuestion
            }
          }
          aboutSectionFive {
            aboutSectionQuote
            aboutSectionQuoteText
            aboutSectionText
            aboutSectionButtonBigText
            aboutSectionButtonBigLink
            aboutSectionCreatorOne {
              aboutSectionCreatorOneName
              aboutSectionCreatorOneText
            }
            aboutSectionCreatorTwo {
              aboutSectionCreatorTwoName
              aboutSectionCreatorTwoText
            }
            aboutSectionCreatorText
            aboutSectionImageBig {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionImageSmall {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionQuestions {
              aboutSectionQuestionsQuestion
            }
          }
          aboutSectionTwo {
            aboutSectionName
            aboutSectionCreatorText
            aboutSectionQuote
            aboutSectionQuoteText
            aboutSectionText
            aboutSectionButtonBigText
            aboutSectionButtonBigLink
            aboutSectionImageOne {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionImageTwo {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionQuestions {
              aboutSectionQuestionsQuestion
            }
          }
          aboutSectionThree {
            aboutSectionName
            aboutSectionText
            aboutSectionQuote
            aboutSectionQuoteText
            aboutSectionCreatorText
            aboutSectionButtonBigText
            aboutSectionButtonBigLink
            aboutSectionImageOne {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionImageTwo {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionQuestions {
              aboutSectionQuestionsQuestion
            }
          }
          aboutSectionFour {
            aboutSectionName
            aboutSectionText
            aboutSectionQuote
            aboutSectionQuoteText
            aboutSectionCreatorText
            aboutSectionButtonBigText
            aboutSectionButtonBigLink
            aboutSectionImageOne {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionImageTwo {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            aboutSectionQuestions {
              aboutSectionQuestionsQuestion
            }
          }
          footerText
        }
      }
      posts(first: 100) {
        nodes {
          slug
          Post {
            videoTitle
            videoDescription
            videoNumber
            videoPremierDate
            videoPromoted
            videoDuration
            videoTrailer
            videoUrl
            videoThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      homePL: page(id: "/strona-glowna/", idType: URI) {
        PageHome {
          allInvestmentsHeading
          allInvestmentsList {
            investmentTitle
            investmentVideoDuration
            investmentVideoUrl
            investmentThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      homeEN: page(id: "/homepage/", idType: URI) {
        PageHome {
          allInvestmentsHeading
          allInvestmentsList {
            investmentTitle
            investmentVideoDuration
            investmentVideoUrl
            investmentThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PostLayout;
