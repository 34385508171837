import React from 'react';
import styled from 'styled-components';
import scrollToSection from 'utils/scrollToSection';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';

const ArrowButton = styled.button`
  background: transparent;
  pointer-events: all;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const ScrollArrow = () => (
  <ArrowButton onClick={() => scrollToSection('#home')} aria-label="scroll up">
    <ArrowUpIcon />
  </ArrowButton>
);

export default ScrollArrow;
